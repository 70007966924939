const BlogContent = [
    {
        images: '01',
        title: 'Running the bulls, by formaBull',
        category: 'Technology',
        link: 'https://www.formabull.io/'
    },
    {
        images: '02',
        title: ' formaBull and React Hook Form Library ',
        category: 'Technology',
        link: 'https://www.formabull.io/'
    },

    {
        images: '03',
        title: 'The Home of the bulls',
        category: 'Technology',
        link: 'https://www.formabull.io/'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        category: '',
        link: 'https://www.formabull.io/'
    },
    {
        images: '05',
        title: ' Getting tickets to the big show',
        category: 'Development',
        link: 'https://www.formabull.io/'
    },
    
]

export default BlogContent;